import { surveyService } from '@/services'
import { EFOPOpenedStatus, ESurveySteps, type TUndef, type IFormPayload, type TOnlyFillableSteps } from '@/types/shared'
import {
  localStorageKeys,
  nextSurveyStep,
  payloadStepKeys,
  previousSurveyStep,
  stepOrder,
  stepTextInformation
} from '@/constants'
import { getActiveStep, isFillableStep } from '@/views/survey/helpers'
import { isEmptyObj } from '@/core/utils/shared'

export const useSurveyStore = defineStore('surveyStore', () => {
  const activeStep = ref<ESurveySteps>(ESurveySteps.NO_ACTIVE_SURVEY)
  const form = ref<IFormPayload>({})
  const isEditMode = ref(false)
  const lastEditedForm = ref<TUndef<{
    date: string
    step: TOnlyFillableSteps
    form: object
    version: number
  }>>(localStorage.getItem(localStorageKeys.SURVEY_LAST_EDITED_FORM)
    ? JSON.parse(localStorage.getItem(localStorageKeys.SURVEY_LAST_EDITED_FORM) as string)
    : undefined)

  const textStepInfomation = computed(() => {
    const isMarried = form.value.step4?.is_in_marriage ?? true
    return stepTextInformation(isMarried)
  })

  const activeStepTextInformation = computed(() => {
    if (!isFillableStep(activeStep.value)) return

    return textStepInfomation.value[activeStep.value]
  })

  const nextSteps = computed(() => {
    const isMarried = form.value.step4?.is_in_marriage
    const isWidow = form.value.step4?.is_widow
    return nextSurveyStep(isMarried ?? true, isWidow ?? true)
  })

  const progress = computed(() => {
    if (!isFillableStep(activeStep.value)) return
    const order = stepOrder[activeStep.value]
    const totalFillableSteps = Object.keys(nextSteps.value).length - 1
    return (order / totalFillableSteps) * 100
  })

  const FOPOpenOrClosedLessThan3Years = computed(() => {
    const step = form.value.step2
    if (!step) return false
    return (step.individual_worker_status === EFOPOpenedStatus.OPEN ||
      step.individual_worker_status === EFOPOpenedStatus.CLOSED_IN_LAST_3_YEARS)
  })

  const isFinalStep = computed(() => {
    return nextSteps.value[activeStep.value] === ESurveySteps.STEP_FINAL
  })

  function setStep (step: ESurveySteps, editMode = false) {
    isEditMode.value = editMode
    activeStep.value = step
  }

  function next () {
    if (isEditMode.value) {
      setActiveStep()
    } else {
      setStep(nextSteps.value[activeStep.value] as ESurveySteps)
    }
  }

  function prev () {
    if (isEditMode.value) {
      setActiveStep()
    } else {
      setStep(previousSurveyStep[activeStep.value])
    }
  }

  async function updateForm (_form: IFormPayload) {
    const updateTriggers = isFinalStep.value || isEditMode.value
    await surveyService.updateForm(_form, updateTriggers, { ...form.value, ..._form })
    form.value = { ...form.value, ..._form }
  }

  async function setInitFormValue () {
    form.value = await surveyService.getFormDetail()
  }

  function setActiveStep () {
    isEditMode.value = false
    activeStep.value = getActiveStep(form.value)
  }

  function getLastEditedForm (step: ESurveySteps) {
    if (step === lastEditedForm.value?.step &&
      !isEmptyObj(form.value) &&
      !form.value[payloadStepKeys[step]] &&
      lastEditedForm.value.version > 1) {
      return lastEditedForm.value.form
    }
  }

  function setLastEditedForm (step: ESurveySteps, form: object) {
    if (!isFillableStep(step)) return

    const value = {
      date: new Date().toLocaleDateString('en-GB'),
      step,
      form,
      version: 2
    }

    localStorage.setItem(localStorageKeys.SURVEY_LAST_EDITED_FORM, JSON.stringify(value))
    lastEditedForm.value = value
  }

  return {
    activeStep,
    activeStepTextInformation,
    isEditMode,
    textStepInfomation,
    progress,
    form,
    FOPOpenOrClosedLessThan3Years,
    isFinalStep,
    nextSteps,
    setStep,
    prev,
    next,
    updateForm,
    getLastEditedForm,
    setLastEditedForm,
    setActiveStep,
    setInitFormValue
  }
})
