import {
  EBankruptcypPocedure,
  ECreditor,
  EDealPlace, EDealType,
  EFOPOpenedStatus, EPropertyForm, EPropertyNewOwner,
  EPropertyOwnTypeStepTen,
  EPropertyPurpose,
  EPropertyType,
  EPropertyTypeStepTen,
  ERentDuration,
  ESurveySteps,
  ETransportStatus,
  type IFormPayload,
  type IStepEightPayload,
  type IStepFIvePayload,
  type IStepSixPayload,
  type TOnlyFillableSteps,
  type TUndef
} from '@/types/shared'
import { payloadStepKeys, previousSurveyStep } from '@/constants'
import { cloneDeep } from 'lodash-es'

export function isFillableStep (step: ESurveySteps): step is TOnlyFillableSteps {
  return ![ESurveySteps.NO_ACTIVE_SURVEY, ESurveySteps.STEP_FINAL, ESurveySteps.HAS_FILLED_SURVEY].includes(step)
}

function getLastFilledStep (form: IFormPayload, step: ESurveySteps) {
  if (step === ESurveySteps.STEP_ONE) return ESurveySteps.NO_ACTIVE_SURVEY

  const prevStep = previousSurveyStep[step]
  if (!isFillableStep(prevStep)) {
    return ESurveySteps.NO_ACTIVE_SURVEY
  }
  if (form[payloadStepKeys[prevStep]]) {
    return step
  } else {
    return getLastFilledStep(form, prevStep)
  }
}

export function getActiveStep (form: IFormPayload) {
  const isMarried = form.step4?.is_in_marriage ?? true
  const isWidow = form.step4?.is_widow ?? true
  const lastStep = isMarried
    ? ESurveySteps.STEP_TWENTY_SIX
    : isWidow
      ? ESurveySteps.STEP_TWENTY
      : ESurveySteps.STEP_NINETEEN

  if (form[payloadStepKeys[lastStep]]) {
    return ESurveySteps.HAS_FILLED_SURVEY
  } else {
    return getLastFilledStep(form, lastStep)
  }
}

export function setDefaultFormValues <T extends Record<string, unknown>, K extends keyof T>
(_default: T, _model: T, _keys: K[] | 'all', _except: K[] = []) {
  const keys = _keys === 'all' ? Object.keys(_default) : _keys
  const set = new Set(_except)
  for (const key of keys) {
    if (!set.has(key as K)) { _model[key] = cloneDeep(_default[key]) }
  }
}

export function getTriggers (_form: IFormPayload) {
  const result: number[] = []

  const income = (() => {
    const step2 = _form.step2
    const step3 = _form.step3

    return (step2?.avg_monthly_salary ?? 0) + (step3?.avg_retire_fee ?? 0) + (step2?.jobs.reduce((acc, job) => {
      return acc + job.avg_salary
    }, 0) ?? 0)
  })()

  const debtsSum = (() => {
    const step5 = _form.step5
    const step6 = _form.step6
    const step7 = _form.step7

    const banks = step5?.credits.reduce((acc: number, credit: IStepFIvePayload['credits'][number]) => {
      const amount = credit.type === ECreditor.FEDERAL_INSPECTION
        ? credit.tax_sum + credit.penalty_sum
        : credit.debt_sum
      return acc + amount
    }, 0) ?? 0

    const aliments = step6?.receivers.reduce((acc: number, aliment: IStepSixPayload['receivers'][number]) => {
      return acc + aliment.sum
    }, 0) ?? 0

    const healthObligations = step7?.health_obligations.reduce((acc, compensation) => {
      return acc + compensation.debt
    }, 0) ?? 0

    const propertyObligations = step7?.property_obligations.reduce((acc, compensation) => {
      return acc + compensation.debt
    }, 0) ?? 0

    const moralObligations = step7?.moral_obligations.reduce((acc, compensation) => {
      return acc + compensation.debt
    }, 0) ?? 0

    const subsidObligations = step7?.subsid_obligations.reduce((acc, compensation) => {
      return acc + compensation.debt
    }, 0) ?? 0

    return banks + aliments + healthObligations + propertyObligations + moralObligations + subsidObligations
  })()

  const triggers: Record<number, () => boolean> = {
    4: () => {
      return _form?.step2?.individual_worker_status === EFOPOpenedStatus.OPEN
    },

    5: () => {
      const step2 = _form.step2
      const step5 = _form.step5
      const step6 = _form.step6
      const step7 = _form.step7
      const income = step2?.avg_monthly_salary ?? 0

      const banks = step5?.credits.reduce((acc: number, credit: IStepFIvePayload['credits'][number]) => {
        const amount = credit.type === ECreditor.FEDERAL_INSPECTION
          ? credit.tax_sum + credit.penalty_sum
          : credit.debt_sum
        return acc + amount
      }, 0) ?? 0

      const aliments = step6?.receivers.reduce((acc: number, aliment: IStepSixPayload['receivers'][number]) => {
        return acc + aliment.sum
      }, 0) ?? 0

      const healthObligations = step7?.health_obligations.reduce((acc, compensation) => {
        return acc + compensation.debt
      }, 0) ?? 0

      const propertyObligations = step7?.property_obligations.reduce((acc, compensation) => {
        return acc + compensation.debt
      }, 0) ?? 0

      const moralObligations = step7?.moral_obligations.reduce((acc, compensation) => {
        return acc + compensation.debt
      }, 0) ?? 0

      const subsidObligations = step7?.subsid_obligations.reduce((acc, compensation) => {
        return acc + compensation.debt
      }, 0) ?? 0

      const sum = banks + aliments + healthObligations + propertyObligations + moralObligations + subsidObligations

      return income > (sum / 36)
    },

    6: () => {
      const step3 = _form.step3
      const step5 = _form.step5
      const step6 = _form.step6
      const step7 = _form.step7
      const income = step3?.avg_retire_fee ?? 0

      const banks = step5?.credits.reduce((acc: number, credit: IStepFIvePayload['credits'][number]) => {
        const amount = credit.type === ECreditor.FEDERAL_INSPECTION
          ? credit.tax_sum + credit.penalty_sum
          : credit.debt_sum
        return acc + amount
      }, 0) ?? 0

      const aliments = step6?.receivers.reduce((acc: number, aliment: IStepSixPayload['receivers'][number]) => {
        return acc + aliment.sum
      }, 0) ?? 0

      const healthObligations = step7?.health_obligations.reduce((acc, compensation) => {
        return acc + compensation.debt
      }, 0) ?? 0

      const propertyObligations = step7?.property_obligations.reduce((acc, compensation) => {
        return acc + compensation.debt
      }, 0) ?? 0

      const moralObligations = step7?.moral_obligations.reduce((acc, compensation) => {
        return acc + compensation.debt
      }, 0) ?? 0

      const subsidObligations = step7?.subsid_obligations.reduce((acc, compensation) => {
        return acc + compensation.debt
      }, 0) ?? 0

      const sum = banks + aliments + healthObligations + propertyObligations + moralObligations + subsidObligations

      return income > (sum / 36)
    },

    7: () => {
      const step3 = _form.step3

      return step3?.debt_for_159 !== undefined && step3?.debt_for_159 > 0
    },

    2: () => {
      const step3 = _form.step3

      return step3?.bankruptcy_procedure === EBankruptcypPocedure.SALE_OF_PROPERTY
    },

    1: () => {
      const step3 = _form.step3

      return step3?.is_plan_for_restruct === true
    },

    8: () => {
      const step3 = _form.step3

      return ((income - 25_000) < (debtsSum / 48)) && !!step3?.is_more_than_500k_last_3_years
    },

    9: () => {
      const step5 = _form.step5

      return !!step5?.credits.some((creditor) => {
        return creditor.deal_place === EDealPlace.LOST && creditor.type === ECreditor.BANK
      })
    },

    10: () => {
      const step5 = _form.step5

      return !!step5?.credits.some((creditor) => {
        return creditor.deal_place === EDealPlace.LOST && creditor.type === ECreditor.MICRO_FINANCE_ORGANIZATION
      })
    },

    12: () => {
      const step5 = _form.step5

      return !!step5?.credits.some((creditor) => {
        return creditor.deal_place === EDealPlace.LOST && creditor.type === ECreditor.OTHER_FIZ
      })
    },

    45: () => {
      const step5 = _form.step5

      const banks = step5?.is_any_credit
        ? step5.credits.reduce((acc: number, credit) => {
          const amount = credit.type === ECreditor.FEDERAL_INSPECTION
            ? (credit.tax_sum ?? 0) + (credit.penalty_sum ?? 0)
            : (credit.debt_sum ?? 0)

          return acc + amount
        }, 0)
        : 0

      return banks < 25_000
    },

    46: () => {
      const step5 = _form.step5

      return step5?.is_any_credit === false
    },

    13: () => {
      const step8 = _form.step8

      return Boolean(
        step8?.is_land_transport_for_util ||
        step8?.is_water_transport_for_util ||
        step8?.is_air_transport_for_util ||
        step8?.air_transports.some(({ transport_status }) => transport_status === ETransportStatus.LOST) ||
        step8?.land_transports.some(({ transport_status }) => transport_status === ETransportStatus.LOST) ||
        step8?.water_transports.some(({ transport_status }) => transport_status === ETransportStatus.LOST)
      )
    },

    35: () => {
      const step8 = _form.step8

      return !!step8?.land_transports.some((car) => {
        const debtCar = car.avg_price !== undefined && car.avg_price !== 0 && (debtsSum / car.avg_price) >= 3

        const case1 = car.is_only_income && car.is_tax && car.license_type !== '-' &&
          debtCar

        const case2 = car.is_only_income &&
          car.is_tax &&
          car.license_type !== '-' &&
          car.is_registered &&
          car.used_for !== '-' &&
          car.is_vital && debtCar

        const case3 = car.is_registered && car.used_for !== '-' && car.is_vital && debtCar

        return ((income - 25_000) < (debtsSum / 48)) &&
          (!car.is_registered || car.is_registered) &&
          !case1 &&
          !case2 &&
          !case3
      })
    },

    34: () => {
      const step8 = _form.step8

      const trigger = (transport: IStepEightPayload['air_transports' | 'land_transports' | 'water_transports'][number], isCar = false) => {
        const debtPrice = transport.avg_price !== undefined &&
          transport.avg_price !== 0 &&
          (debtsSum / transport.avg_price) >= 3

        const licenseType = (transport as IStepEightPayload['land_transports'][number]).license_type as TUndef<IStepEightPayload['land_transports'][number]['license_type']>
        const isRegisteredInRegistry = (transport as IStepEightPayload['land_transports'][number]).is_registered as TUndef<IStepEightPayload['land_transports'][number]['is_registered']>
        const purpose = (transport as IStepEightPayload['land_transports'][number]).used_for as TUndef<IStepEightPayload['land_transports'][number]['used_for']>
        const canProve = (transport as IStepEightPayload['land_transports'][number]).is_vital as TUndef<IStepEightPayload['land_transports'][number]['is_vital']>

        const case1 = licenseType === undefined
          ? transport.is_only_income === true && transport.is_tax === true
          : transport.is_only_income === true && transport.is_tax === true && licenseType !== null && debtPrice

        const case2 =
          licenseType === undefined || isRegisteredInRegistry === undefined || purpose === undefined ||
          canProve === undefined
            ? transport.is_only_income === true &&
            transport.is_tax === true &&
            debtPrice
            : transport.is_only_income === true &&
            transport.is_tax === true &&
            licenseType !== null &&
            isRegisteredInRegistry === true &&
            purpose !== null &&
            canProve === true &&
            debtPrice

        const case3 = isRegisteredInRegistry === undefined || purpose === undefined || canProve === undefined
          ? false
          : isRegisteredInRegistry === true && purpose !== null && canProve === true && debtPrice

        const filledFields = isCar
          ? (isRegisteredInRegistry === false || (isRegisteredInRegistry === true && canProve !== undefined))
          : transport.is_tax === true

        return ((income - 25_000) < (debtsSum / 48)) &&
          filledFields &&
          transport.is_in_current_marriage === true &&
          transport.is_any_mortgage === false &&
          !case1 &&
          !case2 &&
          !case3
      }

      return Boolean(
        step8?.land_transports.some((transport) => trigger(transport)) ||
        step8?.water_transports.some((transport) => trigger(transport)) ||
        step8?.air_transports.some((transport) => trigger(transport))
      )
    },

    14: () => {
      const step8 = _form.step8

      return ((income - 25_000) < (debtsSum / 48)) && !!step8?.land_transports.some(({ is_any_borrower }) => {
        return is_any_borrower
      })
    },

    15: () => {
      const step8 = _form.step8

      return ((income - 25_000) < (debtsSum / 48)) && !!step8?.water_transports.some(({ is_any_borrower }) => {
        return is_any_borrower
      })
    },

    16: () => {
      const step8 = _form.step8

      return ((income - 25_000) < (debtsSum / 48)) && !!step8?.air_transports.some(({ is_any_borrower }) => {
        return is_any_borrower
      })
    },

    30: () => {
      const step8 = _form.step8

      return ((income - 25_000) < (debtsSum / 48)) && !!step8?.water_transports.some((transport) => {
        return transport.relation_type !== '-' &&
          !(transport.is_only_income &&
            transport.is_tax &&
            transport.avg_price !== undefined &&
            transport.avg_price > 0 &&
            ((debtsSum / transport.avg_price) >= 3))
      })
    },

    31: () => {
      const step8 = _form.step8

      return ((income - 25_000) < (debtsSum / 48)) && !!step8?.air_transports.some((transport) => {
        return transport.relation_type !== '-' &&
          !(transport.is_only_income &&
            transport.is_tax &&
            transport.avg_price !== undefined &&
            transport.avg_price > 0 &&
            ((debtsSum / transport.avg_price) >= 3))
      })
    },

    17: () => {
      const step9 = _form.step9

      return !!step9?.guns.some((gun) => gun.is_lost)
    },

    25: () => {
      const step4 = _form.step4
      const step10 = _form.step10

      return ((income - 25_000) < (debtsSum / 48)) && !!step10?.properties.some((property) => {
        return property.property_type === EPropertyTypeStepTen.APARTAMENTS
      }) && !!(step4?.is_in_marriage || step4?.is_divorced)
    },

    39: () => {
      const step4 = _form.step4
      const step10 = _form.step10

      return ((income - 25_000) < (debtsSum / 48)) && !!step10?.properties.some((property) => {
        return property.property_type === EPropertyTypeStepTen.APARTAMENTS
      }) && !!step4?.is_not_married
    },

    28: () => {
      const step10 = _form.step10

      return ((income - 25_000) < (debtsSum / 48)) && step10 !== undefined && step10.properties.length > 1
    },

    40: () => {
      const step10 = _form.step10
      const step4 = _form.step4

      return !!step10?.properties.some((estate) => {
        const familyStatus = step4?.is_in_marriage || step4?.is_divorced || step4?.is_not_married

        return estate.is_only_place && estate.is_any_mortgage && estate.own_type !== '-' && !!familyStatus
      })
    },

    41: () => {
      const step10 = _form.step10
      const step4 = _form.step4

      return !!step10?.properties.some((estate) => {
        const cases: boolean[] = [
          estate.is_any_mortgage && estate.own_type === EPropertyOwnTypeStepTen.SHARE && !!step4?.is_in_marriage,
          estate.is_any_mortgage && estate.own_type === EPropertyOwnTypeStepTen.MUTUAL && !!step4?.is_in_marriage,
          estate.is_any_mortgage && estate.own_type === EPropertyOwnTypeStepTen.SHARE && !!step4?.is_not_married,
          estate.is_any_mortgage && estate.own_type === EPropertyOwnTypeStepTen.MUTUAL && !!step4?.is_not_married,
          estate.is_any_mortgage && estate.own_type === EPropertyOwnTypeStepTen.SHARE && !!step4?.is_divorced,
          estate.is_any_mortgage && estate.own_type === EPropertyOwnTypeStepTen.MUTUAL && !!step4?.is_divorced,
          estate.is_any_mortgage && estate.own_type === EPropertyOwnTypeStepTen.INDIVIDUAL && !!step4?.is_in_marriage,
          estate.is_any_mortgage && estate.own_type === EPropertyOwnTypeStepTen.INDIVIDUAL && !!step4?.is_divorced,
          estate.is_any_mortgage && estate.own_type === EPropertyOwnTypeStepTen.INDIVIDUAL && !!step4?.is_not_married,
          !estate.is_any_mortgage && estate.own_type === EPropertyOwnTypeStepTen.INDIVIDUAL && !!step4?.is_not_married,
          !estate.is_any_mortgage && estate.own_type === EPropertyOwnTypeStepTen.INDIVIDUAL && !!step4?.is_divorced
        ]

        const isProperEstateType = [
          EPropertyTypeStepTen.APARTAMENTS,
          EPropertyTypeStepTen.LAND_PLOT,
          EPropertyTypeStepTen.GARAGE,
          EPropertyTypeStepTen.COMMERCIAL,
          EPropertyTypeStepTen.OTHER].includes(estate.property_type)

        return isProperEstateType && cases.some((_case) => _case)
      })
    },

    42: () => {
      const step10 = _form.step10
      const step4 = _form.step4

      return !!step10?.properties.some((estate) => {
        const isProperEstateType = [
          EPropertyTypeStepTen.APARTAMENTS,
          EPropertyTypeStepTen.LAND_PLOT,
          EPropertyTypeStepTen.GARAGE,
          EPropertyTypeStepTen.COMMERCIAL,
          EPropertyTypeStepTen.OTHER].includes(estate.property_type)

        return isProperEstateType && !estate.is_any_mortgage && !!step4?.is_in_marriage && estate.own_type !== '-'
      })
    },

    43: () => {
      const step10 = _form.step10
      const step4 = _form.step4

      return !!step10?.properties.some((estate) => {
        const isProperEstateType = [
          EPropertyTypeStepTen.APARTAMENTS,
          EPropertyTypeStepTen.LAND_PLOT,
          EPropertyTypeStepTen.GARAGE,
          EPropertyTypeStepTen.COMMERCIAL,
          EPropertyTypeStepTen.OTHER].includes(estate.property_type)

        return isProperEstateType &&
          !estate.is_any_mortgage &&
          estate.own_type !== '-' &&
          estate.own_type !== EPropertyOwnTypeStepTen.INDIVIDUAL &&
          !!(step4?.is_not_married || step4?.is_divorced)
      })
    },

    19: () => {
      const step10 = _form.step10

      return ((income - 25_000) < (debtsSum / 48)) && !!step10?.properties.some((estate) => {
        return estate.is_any_borrower
      })
    },

    37: () => {
      const step10 = _form.step10

      return ((income - 25_000) < (debtsSum / 48)) && !!step10?.properties.some((estate) => {
        return estate.property_aim === EPropertyPurpose.COMMERCIAL &&
          estate.rent_duration === ERentDuration.MORE_THAN_ONE_YEAR
      })
    },

    20: () => {
      const step11 = _form.step11

      return ((income - 25_000) < (debtsSum / 48)) && !!step11?.properties.some((property) => {
        return property.avg_sum > 500_000
      })
    },

    21: () => {
      const step13 = _form.step13

      return ((income - 25_000) < (debtsSum / 48)) && !!step13?.accounts.some((account) => {
        return account.amount > 100_000
      })
    },

    22: () => {
      const step13 = _form.step13

      return ((income - 25_000) < (debtsSum / 48)) && !!step13?.deposits.some((account) => {
        return account.amount > 10_000
      })
    },

    27: () => {
      const step20 = _form.step20
      const step22 = _form.step22

      return ((income - 25_000) < (debtsSum / 48)) && !!(step20?.marriage.has_property_in_rf || step22?.has_property)
    },

    23: () => {
      const step19 = _form.step19

      return ((income - 25_000) < (debtsSum / 36)) && !!step19?.deals.some((deal) => !deal.is_deal_with_creditor)
    },

    44: () => {
      const step22 = _form.step22

      return ((income - 25_000) < (debtsSum / 48)) && !!step22?.properties.some((property) => {
        return property.type === EPropertyType.APARTMENTS
      })
    },

    24: () => {
      const step19 = _form.step19

      return ((income - 25_000) < (debtsSum / 48)) && !!step19?.deals.some((deal) => {
        const month = isNaN(Number(deal.delay_after_time)) ? 0 : +deal.delay_after_time

        const cases: boolean[] = [
          deal.property_type === EPropertyForm.NOT_REAL_ESTATE &&
          deal.is_deal_with_creditor &&
          (deal.is_delay || deal.is_on_pledge),

          deal.property_type === EPropertyForm.NOT_REAL_ESTATE &&
          deal.is_deal_with_creditor &&
          !deal.is_on_pledge &&
          !deal.is_delay &&
          deal.is_deal_by_market &&
          deal.property_new_owner !== '-' &&
          deal.property_new_owner !== EPropertyNewOwner.NO_RELATIONSHIP,

          deal.property_type === EPropertyForm.NOT_REAL_ESTATE &&
          (deal.type === EDealType.GIVING || deal.type === EDealType.TRADE) &&
          !deal.is_on_pledge &&
          deal.property_new_owner !== '-' &&
          deal.property_new_owner !== EPropertyNewOwner.NO_RELATIONSHIP &&
          !deal.is_delay &&
          month <= 6,

          deal.property_type === EPropertyForm.NOT_REAL_ESTATE &&
          (deal.type === EDealType.GIVING || deal.type === EDealType.TRADE) &&
          !deal.is_on_pledge &&
          deal.property_new_owner === EPropertyNewOwner.NO_RELATIONSHIP &&
          !deal.is_delay &&
          month <= 6,

          deal.property_type === EPropertyForm.REAL_ESTATE &&
          deal.type === EDealType.BUY_SELL &&
          !deal.is_delay &&
          deal.property_new_owner !== '-' &&
          month > 6 &&
          !deal.is_deal_by_market &&
          !deal.is_only_property,

          deal.property_type === EPropertyForm.REAL_ESTATE &&
          deal.type === EDealType.BUY_SELL &&
          deal.property_new_owner !== '-' &&
          deal.is_delay &&
          !deal.is_only_property,

          deal.property_type === EPropertyForm.REAL_ESTATE &&
          deal.type === EDealType.GIVING &&
          deal.is_on_pledge &&
          deal.property_new_owner !== '-' &&
          deal.property_new_owner !== EPropertyNewOwner.NO_RELATIONSHIP &&
          deal.is_delay &&
          !deal.is_only_property,

          deal.property_type === EPropertyForm.REAL_ESTATE &&
          deal.type === EDealType.GIVING &&
          !deal.is_on_pledge &&
          deal.property_new_owner !== '-' &&
          !deal.is_only_property,

          deal.property_type === EPropertyForm.REAL_ESTATE &&
          deal.type === EDealType.TRADE &&
          deal.is_on_pledge &&
          deal.property_new_owner !== '-' &&
          deal.is_delay &&
          !deal.is_only_property,

          deal.property_type === EPropertyForm.REAL_ESTATE &&
          deal.type === EDealType.TRADE &&
          !deal.is_on_pledge &&
          deal.property_new_owner !== '-' &&
          !deal.is_only_property
        ]

        return cases.some((_case) => _case)
      })
    }
  }

  for (const _key in triggers) {
    const key = +_key as keyof typeof triggers
    const triggerFunction = triggers[key]

    if (triggerFunction()) {
      result.push(key)
    }
  }

  return result
}
