import type { RouteRecordRaw } from 'vue-router'

import { routesNames } from '@/router/route-names'
import { authRoutes } from '@/views/auth/auth.routes'
import { mainRoutes } from '@/views/main/main.routes'
import { surveyRoutes } from '@/views/survey/survey.routes'
import { documentsRoutes } from '@/views/documents/documents.routes'

import BlankLayout from '@/layouts/BlankLayout.vue'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import { tariffRoutes } from '@/views/tariff/tariff.routes'

const defaultLayoutRoutes: RouteRecordRaw = {
  path: '/',
  redirect: { name: routesNames.main },
  component: DefaultLayout,
  children: [
    mainRoutes,
    surveyRoutes,
    documentsRoutes,
    tariffRoutes
  ]
}
const blankLayoutRoutes: RouteRecordRaw = {
  path: '/',
  redirect: { name: routesNames.login },
  component: BlankLayout,
  children: [
    authRoutes
  ]
}

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: routesNames.rootPage,
    redirect: { name: routesNames.main },
    component: BlankLayout,
    children: [
      defaultLayoutRoutes,
      blankLayoutRoutes
    ]
  },
  {
    path: '/:notFound(.*)*',
    name: routesNames.notFound,
    redirect: { name: routesNames.rootPage }
  }

]

export { routes }
